<template>
  <div class="space-y-16">
    <div>
      <div class="heading-3 flex flex-col justify-between md:flex-row">
        <h3>{{ insuranceOffer.title }}</h3>

        <div v-if="insuranceOffer.isMonthly">
          {{
            i18n(translations.monthlyPrice, {
              price: i18n.price(insuranceOffer.price),
            })
          }}
        </div>
        <div v-else>{{ i18n.price(insuranceOffer.price) }}</div>
      </div>

      <h4 class="body-2">
        {{ deviceName }}
      </h4>
    </div>

    <div class="space-y-8">
      <div
        v-for="benefit in insuranceOffer.benefits.long"
        :key="benefit"
        class="flex gap-4"
      >
        <IconCheckInCircle class="text-static-success-hi mt-2 h-16 w-16" />
        <div class="text-static-default-low body-2">{{ benefit }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { InsuranceOffer } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'

import translations from './CatchUpModalOffer.translations'

defineProps<{
  insuranceOffer: InsuranceOffer
  deviceName: string
}>()

const i18n = useI18n()
</script>
