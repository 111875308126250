import { createHttpEndpoint } from '../../utils'

import type { PopularityStats } from './popularity-stats.types'

/**
 * Service in charge of exposing products popularity statistics
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/popularity-stats-api/definition#/default/get_products_tags}
 */
export const getPopularityStats = createHttpEndpoint<PopularityStats[]>({
  method: 'GET',
  operationId: 'getPopularityStats',
  path: '/api/popularity-stats/v1/products/tags/',
})
