<template>
  <div>
    <SalesPopularityTag :is-out-of-stock :popularity-stats />
    <RevCard
      :id="item.listingId"
      class="space-y-32 p-24 md:space-y-36"
      :class="{
        [styles.containerAnimation]: popularityStats,
        'mt-[-44px]': popularityStats,
      }"
      data-qa="cart"
    >
      <div class="space-y-12">
        <Product
          :is-out-of-stock
          :item
          :quantity
          @remove="emit('remove')"
          @update-quantity="(quantity) => emit('update-quantity', quantity)"
        />
      </div>

      <ErrorBoundary :owners="['bot-squad-b2cservices-front']">
        <SelectedMobilePlan
          v-if="item.mobilePlan"
          :mobile-plan-offer="item.mobilePlan.selectedOffer"
          @remove="emit('update', { ...item, mobilePlan: null })"
        />

        <Insurances
          v-if="!isOutOfStock && item.insuranceOffers.length > 0"
          :insurance-offers="item.insuranceOffers"
          :listing-id="item.listingId"
          :model="item.model"
          :should-display-error
          :with-catch-up-modal="isFirstItemWithUnselectedInsurance"
          @ignore-catchup-modal="emit('ignore-catchup-modal')"
          @update="handleInsuranceUpdate"
        />
      </ErrorBoundary>
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import { computed, useCssModule } from 'vue'

import type { InsuranceOfferWithSelectedState } from '@backmarket/http-api'
import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import type { PopularityStats } from '@backmarket/http-api/src/api-specs-checkout/popularity-stats/popularity-stats.types'
import ErrorBoundary from '@backmarket/nuxt-module-logger/components/ErrorBoundary.vue'
import { RevCard } from '@ds/components/Card'

import Insurances from './components/Insurances/Insurances.vue'
import Product from './components/Product/Product.vue'
import SalesPopularityTag from './components/SalesPopularityTag/SalesPopularityTag.vue'
import SelectedMobilePlan from './components/SelectedMobilePlan/SelectedMobilePlan.vue'

const props = defineProps<{
  item: CartItem
  popularityStats?: PopularityStats
  quantity: number
  isFirstItemWithUnselectedInsurance: boolean
  shouldDisplayError: boolean
  isCollectionPointAddressSet: boolean
}>()

const emit = defineEmits<{
  remove: []
  'update-quantity': [quantity: number]
  'ignore-catchup-modal': []
  update: [updatedItem: CartItem]
}>()

const styles = useCssModule()

function handleInsuranceUpdate(
  updatedInsuranceOffers: InsuranceOfferWithSelectedState[],
) {
  return emit('update', {
    ...props.item,
    insuranceOffers: updatedInsuranceOffers,
  })
}

const isOutOfStock = computed(() => props.item.available !== 'yes')
</script>

<style module>
@keyframes slide-in {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(32px);
  }
}

.containerAnimation {
  animation: 150ms cubic-bezier(0, 0, 0.4, 1) forwards slide-in;
}
</style>
