<template>
  <div>
    <SwapSummary
      v-if="swapStore.offer"
      class="mb-56 mt-32"
      data-qa="swap-summary"
      data-test="swap-summary"
      :details="swapStore.offer.diagnosticSummary"
      :price="swapStore.offer.price"
      :shipping-mode="swapStore.offer.defaultShippingModeId"
      :title="swapStore.offer.title"
      :total-price-after-buyback="cartStore.totalPriceAfterBuyback"
      @delete-swap="handleDeleteSwap"
    />

    <RevCard v-if="swapStore.hasAdvertisement" class="mb-56 mt-32 md:hidden">
      <TheCatcherComponent>
        <SwapBlock
          compact
          :has-offer="swapAdvertisement.hasOffer"
          :modal-name="BUYBACK_MODAL_NAMES.SWAP_CART"
          :model="swapAdvertisement.model"
          :price="swapAdvertisement.price"
          :swapModel="swapAdvertisement.model"
          tracking-label="swap_from_cart"
          zone="swap_cart"
          @confirmation="handleSwapConfirmation"
        />
      </TheCatcherComponent>
    </RevCard>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { deleteSwap } from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { useBuybackOffer } from '@backmarket/nuxt-layer-buyback/composables/buybackOffer/useBuybackOffer'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'

import TheCatcherComponent from '~/scopes/buyback/components/TheCatcher/TheCatcherComponent.vue'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'
import SwapSummary from '~/scopes/buyback/swap/components/SwapSummary/SwapSummary.vue'

import useHandleUnauthorizedUser from '../../../../composables/useHandleUnauthorizedUser'
import { useCartStore } from '../../../../stores/cartStore'
import { useSwapStore } from '../../../../stores/swapStore'

import translations from './Swap.translations'

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const { resetBuybackOffer } = useBuybackOffer()
const cartStore = useCartStore()
const swapStore = useSwapStore()
const tracking = useTracking()
const i18n = useI18n()

const swapAdvertisement = computed(() => ({
  model:
    cartStore.items.length === 1
      ? cartStore.items[0].model
      : i18n(translations.swapMultipleItemsTitle),
  price: i18n.price(swapStore.advertisement?.cartPriceWithSwapDiscount ?? ''),
  hasOffer: swapStore.hasAdvertisement,
}))

const handleSwapConfirmation = async () => {
  await cartStore.fetchCart()
}

const handleDeleteSwap = async () => {
  try {
    await $httpFetch(deleteSwap)
    const eventName = 'delete_swap'

    tracking.trackSwap({
      label: eventName,
      action: 'funnel > Step 4',
      ...(swapStore.offer && swapStore.offer.diagnosticPayload),
    })

    tracking.trackClick({
      name: eventName,
      value: { ...(swapStore.offer && swapStore.offer.diagnosticPayload) },
      zone: 'swap',
    })

    await cartStore.fetchCart()
    resetBuybackOffer()
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error deleting swap',
    )
  }
}
</script>
