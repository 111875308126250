<template>
  <ul
    class="text-static-default-hi rounded-md flex flex-col gap-8"
    :class="{
      'bg-static-info-low body-2-bold p-8': hasBackgroundDisplay,
      'body-2 py-8': !hasBackgroundDisplay,
    }"
  >
    <li v-if="item.providedAccessories.length" class="flex items-center gap-8">
      <IconChargingWire class="shrink-0" />
      <AccessoriesSummary :accessories="item.providedAccessories" />
    </li>
    <li class="flex items-center gap-8">
      <IconQuality class="shrink-0" />
      <Warranty />
    </li>
    <li class="flex gap-8">
      <IconTruckExpress class="shrink-0" />
      <ShippingPromise :choices="item.options[0].choices" class="pt-2" />
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { CartItem } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { IconChargingWire } from '@ds/icons/IconChargingWire'
import { IconQuality } from '@ds/icons/IconQuality'
import { IconTruckExpress } from '@ds/icons/IconTruckExpress'

import AccessoriesSummary from '../AccessoriesSummary/AccessoriesSummary.vue'
import ShippingPromise from '../ShippingPromise/ShippingPromise.vue'
import Warranty from '../Warranty/Warranty.vue'

defineProps<{
  item: CartItem
}>()

const experiments = useExperiments()

const hasBackgroundDisplay = computed(
  () =>
    experiments['experiment.cartItemCardPerks'] ===
    'newItemCardPerksBackground',
)
</script>
