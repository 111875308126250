<template>
  <div class="whitespace-break-spaces">
    <div v-if="choices.some((choice) => choice.price === 0)">
      {{
        i18n(
          translations.shippingPromise,
          getDeliveryDates({
            choices,
          }),
        )
      }}
    </div>

    <div v-if="choices.some((choice) => choice.price !== 0)">
      {{
        i18n(
          translations.shippingPromiseExpress,
          getDeliveryDates({
            choices,
            express: true,
          }),
        )
      }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ShippingChoice } from '@backmarket/http-api/src/api-specs-checkout/cart/cart.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { useDeliveryDateAdjustment } from '~/scopes/product/composables/useDeliveryDateAdjustement'

import translations from './ShippingPromise.translations'

defineProps<{
  choices: ShippingChoice[]
}>()

const i18n = useI18n()
const deliveryDateAdjustment = useDeliveryDateAdjustment()

const getDeliveryDates = ({
  choices,
  express = false,
}: {
  choices: ShippingChoice[]
  express?: boolean
}) => {
  let earliest: Date | undefined
  let latest: Date | undefined
  let amount = ''

  choices
    .filter((choice) => (express ? choice.price !== 0 : choice.price === 0))
    .forEach((choice) => {
      if (choice.earliestArrivalDate) {
        const newDate = new Date(choice.earliestArrivalDate)
        earliest = earliest ?? newDate
        earliest = newDate < earliest ? newDate : earliest
      }

      if (choice.latestArrivalDate) {
        const newDate = new Date(choice.latestArrivalDate)
        latest = latest ?? newDate

        if (newDate >= latest) {
          latest = newDate
          amount = choice.priceWithCurrency
        }
      }
    })

  return {
    earliest: earliest
      ? i18n.date(deliveryDateAdjustment(earliest), {
          month: 'short',
          day: 'numeric',
        })
      : '',
    latest: latest ? i18n.date(latest, { month: 'short', day: 'numeric' }) : '',
    amount,
  }
}
</script>
