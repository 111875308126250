<template>
  <RevContentBlock
    v-if="hasNoItems"
    :button-label="i18n(translations.noItemsButton)"
    data-qa="empty-cart-block"
    :image-props="
      {
        alt: '',
        height: 463,
        src: '/img/checkout/emptyBasket.svg',
        style: {
          height: 'auto',
          margin: '0 auto',
          padding: '0 24px',
          width: '100%',
        },
        width: 438,
      } as any
    "
    :surtitle="i18n(translations.noItemsSurtitle)"
    :title="i18n(translations.noItemsTitle)"
    :to="resolveLocalizedRoute({ name: HOME })"
  >
    <p class="body-1">
      {{ i18n(translations.noItemsDescription) }}
    </p>
  </RevContentBlock>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContentBlock } from '@ds/components/ContentBlock'

import { useRouteLocationWithLocale } from '~/composables/useRouteLocationWithLocale'
import { HOME } from '~/scopes/home/route-names'

import { useCartStore } from '../../../../stores/cartStore'

import translations from './EmptyCart.translations'

const i18n = useI18n()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const cartStore = useCartStore()

const hasNoItems = computed(() => isEmpty(cartStore.items))
</script>
