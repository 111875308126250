<template>
  <div class="space-y-12">
    <RevIllustration
      alt="BackUp logo"
      class="mb-8"
      :height="18"
      src="/img/insurances/backUpLogo.svg"
      :width="84"
    />

    <FormattedMessage
      class="text-static-default-hi body-1"
      :definition="translations.title"
    >
      <template #deviceName>
        <span class="body-1-bold"> {{ model }}</span>
      </template>
    </FormattedMessage>

    <BenefitsList :benefits />

    <RevLink
      class="block"
      target="_blank"
      :tracking="{
        name: 'insurance_faq',
        zone: 'cart_insurance_selected',
      }"
      @click="openModal(MODAL_NAMES.BACK_UP_INSURANCE_MODAL)"
    >
      <div class="text-static-default-low body-2-link">
        {{ i18n(translations.faqButton) }}
      </div>
    </RevLink>

    <InsuranceOffers
      :id="listingId"
      data-test="insurances"
      :offers="insuranceOffers"
      :selectedOfferId="selectedOffer.id"
      trackingZone="cart"
      @select="(offer) => emit('update', offer)"
    />

    <InsuranceModal :name="MODAL_NAMES.BACK_UP_INSURANCE_MODAL" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type {
  InsuranceOffer,
  InsuranceOfferWithSelectedState,
} from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import InsuranceModal from '~/scopes/insurance/components/InsuranceOffers/components/InsuranceModal/InsuranceModal.vue'
import { MODAL_NAMES } from '~/scopes/insurance/constants'

import translations from './InsuranceSelectionRedesign.translations'
import BenefitsList from './components/BenefitsList/BenefitsList.vue'
import InsuranceOffers from './components/InsuranceOffers/InsuranceOffers.vue'

const i18n = useI18n()

const props = defineProps<{
  model: string
  selectedOffer: InsuranceOfferWithSelectedState
  insuranceOffers: InsuranceOfferWithSelectedState[]
  listingId: string
}>()

const emit = defineEmits<{
  update: [updatedInsuranceOffers: InsuranceOffer]
}>()

const benefits = computed(() => {
  if (props.selectedOffer.defaultOffer) {
    const insurancesWithoutDefaultOffer = props.insuranceOffers.filter(
      (offer) => !offer.defaultOffer,
    )

    return insurancesWithoutDefaultOffer[0].benefits.long
  }

  return props.selectedOffer.benefits.long
})
</script>
