<template>
  <div class="flex-1">
    <RevRadio
      :id
      class="h-full"
      :class="$style.radio"
      :model-value="isSelected"
      :tag-props
      :value="id"
      variant="full"
      @click="handleClick"
    >
      <template #label>
        <div class="body-1 flex justify-between">
          <span :class="{ 'body-1-bold': isSelected }">
            {{ props.offer.title }}
          </span>

          <div class="text-static-default-low body-2 mr-8 pt-3">
            <span v-if="offer.isMonthly">
              {{
                i18n(translations.monthlyPrice, {
                  price: i18n.price(offer.price),
                })
              }}
            </span>

            <span v-else>
              {{ i18n.price(offer.price) }}
            </span>
          </div>
        </div>
      </template>

      <template
        v-if="hasMonthlyOffers && marketplaceCode === MarketplaceCode.EU"
        #description
      >
        {{ description }}
      </template>
    </RevRadio>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type InsuranceOffer, MarketplaceCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevRadio } from '@ds/components/Radio'
import { VariantsEnum } from '@ds/components/Tag/Tag.constant'

import translations from './InsuranceRadio.translations'

const i18n = useI18n()
const { trackClick } = useTracking()
const {
  marketplace: { marketplaceCode },
} = useMarketplace()

const props = defineProps<{
  id: string
  offer: InsuranceOffer
  isSelected: boolean
  trackingZone: string
  hasMonthlyOffers: boolean
  tagProps: { label: string; variant: VariantsEnum } | undefined
}>()

const emit = defineEmits(['select', 'unselect'])

const description = computed(() => {
  if (props.offer.isMonthly) {
    return i18n(translations.minimumCoverageDuration, {
      annualPrice: i18n.price(parseFloat(props.offer.price.amount) * 12),
    })
  }

  return i18n(translations.oneTimePayment)
})

function handleClick() {
  trackClick({
    name: props.offer.title,
    value: props.offer.isMonthly ? 'monthly' : 'upfront',
    zone: props.trackingZone,
  })

  emit(props.isSelected ? 'unselect' : 'select')
}
</script>

<style lang="css" module>
.radio > label {
  @apply h-full;
}
</style>
