export default {
  faqLink: {
    id: 'selected_catch_up_offer_link',
    defaultMessage: 'What BackUp gets you',
  },
  illustrationAlternative: {
    id: 'selected_catch_up_offer_illustration_alternative',
    defaultMessage: 'Back up logo',
  },
  remove: {
    id: 'selected_catch_up_offer_remove',
    defaultMessage: 'Remove',
  },
  faqLinkValue: {
    id: 'selected_catch_up_offer_link_value',
    defaultMessage:
      'https://help.backmarket.com/hc/en-us/articles/4404010751762-What-s-covered-by-the-Protection-Plan-and-why-should-I-buy-it',
  },
}
