export default {
  buttonCheckout: {
    id: 'recap_button',
    defaultMessage: 'Checkout',
  },
  buttonCheckoutABTest: {
    id: 'recap_button_abtest',
    defaultMessage: 'Checkout',
  },
  securePayment: {
    id: 'cart_secure_payment',
    defaultMessage: 'Secure payment with Adyen',
  },
  mainTitle: {
    id: 'main_title',
    defaultMessage: 'Your cart',
  },
  swapMultipleItemsTitle: {
    id: 'funnel_swap_multiple_items_title',
    defaultMessage: 'Cart',
  },
  chooseCollectionPointButton: {
    id: 'funnel_cart_page_choose_collection_point_button',
    defaultMessage: 'Choose pickup location',
  },
  editCollectionPointButton: {
    id: 'funnel_cart_page_edit_collection_point_button',
    defaultMessage: 'Edit pickup location',
  },
  mandatoryCollectionPoint: {
    id: 'funnel_cart_page_mandatory_collection_point',
    defaultMessage:
      'Please choose a pickup location or select another shipping method.',
  },
  mandatoryInsurance: {
    id: 'funnel_cart_page_mandatory_insurance',
    defaultMessage: 'Please choose a coverage option.',
  },
  youSave: {
    id: 'funnel_cart_page_you_save',
    defaultMessage: 'You save {reduction} vs. new',
  },
  insurancePromocodeBanner: {
    id: 'insurance_promocode_banner',
    defaultMessage:
      'Get 25$ off with promo code BackMeUp (and peace of mind) when you bundle your device with a protection plan (minimum order value : 250$).',
  },
  insurancePromocodeBannerAriaLabel: {
    id: 'insurance_promocode_banner_close_button_aria_label',
    defaultMessage: 'Close banner',
  },
}
