import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  enrollmentPeriod: {
    id: 'cart_bouygues_mobile_plan_enrollment_period',
    defaultMessage: '{duration} engagement',
  },
  firstPeriodPrice: {
    id: 'cart_bouygues_mobile_plan_first_period_price',
    defaultMessage: '{price}/month',
  },
  firstPeriodDuration: {
    id: 'cart_bouygues_mobile_plan_first_period_duration',
    defaultMessage: 'for {duration}',
  },
  secondPeriodPrice: {
    id: 'cart_bouygues_mobile_plan_second_period_price',
    defaultMessage: 'then {price}/month',
  },
  shippingDuration: {
    id: 'cart_bouygues_mobile_plan_shipping_duration',
    defaultMessage: 'Get it in {durationInBusinessDays} business days',
  },
  removeButton: {
    id: 'cart_bouygues_mobile_plan_remove_button',
    defaultMessage: 'Remove',
  },
}

export default translations
