export default {
  title: {
    id: 'insurance_selection_redesign_title',
    defaultMessage: 'Protect your {deviceName, html}, and your peace of mind.',
  },
  faqButton: {
    id: 'insurance_selection_redesign_link',
    defaultMessage: 'What BackUp gets you',
  },
}
