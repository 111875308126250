<template>
  <div class="flex flex-col gap-12 md:flex-row">
    <InsuranceRadio
      v-for="(offer, index) in offers.filter(
        (offer) => !offer.isCatchUpOffer && !offer.defaultOffer,
      )"
      :id="createIdentifier(offer.id)"
      :key="createIdentifier(offer.id)"
      class="pt-10 only-of-type:w-full"
      :data-qa="`insurance-offer-${index}`"
      :has-monthly-offers
      :is-selected="selectedOfferId === offer.id"
      :offer
      :tag-props="getTagProps(offer, index)"
      :tracking-zone
      @select="handleSelect(offer)"
      @unselect="handleSelect(defaultOffer)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { type InsuranceOffer, MarketCountryCode } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { VariantsEnum as TagVariants } from '@ds/components/Tag/Tag.constant'

import translations from './InsuranceOffers.translations'
import InsuranceRadio from './components/InsuranceRadio/InsuranceRadio.vue'

const { market } = useMarketplace()
const i18n = useI18n()

const props = defineProps<{
  offers: InsuranceOffer[]
  trackingZone: string
  id: string
  selectedOfferId?: number | string
}>()

const emit = defineEmits<{
  select: [insuranceOffer: InsuranceOffer]
}>()

const defaultOffer = computed(() => {
  return props.offers.find((offer) => offer.defaultOffer)
})

function hasMonthlyTag(offer: InsuranceOffer) {
  return offer.isMonthly && market.countryCode === MarketCountryCode.US
}

function hasMonthlyTags(offers: InsuranceOffer[]) {
  return offers.some(hasMonthlyTag)
}

const hasMonthlyOffers = computed(() => {
  return props.offers.some((offer) => offer.isMonthly)
})

function handleSelect(offer?: InsuranceOffer) {
  if (!offer) return

  emit('select', offer)
}

// create a unique identifier depending of the listing if. It avoid conflit when same insurance offers are displayed for different product.
function createIdentifier(offerId: number | string) {
  return props.id + offerId
}

function getTagProps(offer: InsuranceOffer, index: number) {
  if (offer.isMonthly && market.countryCode === MarketCountryCode.US)
    return {
      label: i18n(translations.monthlyPaymentTag),
      variant: TagVariants.Primary,
    }
  if (index === 0 && !hasMonthlyTags(props.offers))
    return {
      label: i18n(translations.recommendedTag),
      variant: TagVariants.Primary,
    }

  return undefined
}
</script>
