<template>
  <div>
    <div v-if="shouldDisplayInsuranceOffers" class="space-y-16">
      <InsuranceSelectionRedesign
        v-if="isInsuranceSelectionRedesignEnabled"
        :insurance-offers
        :listing-id
        :model
        :selected-offer
        @update="updateSelectedOption"
      />

      <InsuranceSelection
        v-else
        :insurance-offers
        :listing-id
        :model
        :selected-offer-id="selectedOffer.id"
        @update="updateSelectedOption"
      />

      <InsuranceCompliancy
        :id="`compliancy-${listingId}`"
        :offer="selectedOffer"
        @update="handleUpdateCompliancy"
      />
    </div>

    <template v-else>
      <SelectedCatchUpOffer
        v-if="isCatchUpOfferSelected"
        class="my-32 md:my-56"
        :insurance-offer="selectedOffer"
        @remove="handleRemoveOffer"
        @update-compliancy="handleUpdateCompliancy"
      />
    </template>

    <p
      v-if="hasCompliancyError && shouldDisplayError"
      class="text-static-danger-hi mt-3"
    >
      {{ i18n(translations.compliancyError) }}
    </p>

    <CatchUpModal
      v-if="withCatchUpModal"
      :device-name="model"
      :insurance-offers
      @decline="handleDeclineCatchUpModal"
      @select-insurance-offer="handleSelectCatchUpModal"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import type {
  InsuranceOffer,
  InsuranceOfferWithSelectedState,
} from '@backmarket/http-api'
import {
  postAcceptAgreement,
  postUpdateInsuranceOffer,
} from '@backmarket/http-api/src/api-specs-checkout/cart/cart'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTheToast } from '@backmarket/nuxt-module-toast/useTheToast'
import { useThrottleFn } from '@backmarket/utils/composables/useThrottleFn'
import { closeModal } from '@ds/components/ModalBase'

import useHandleUnauthorizedUser from '~/scopes/checkout/composables/useHandleUnauthorizedUser'
import { useCartStore } from '~/scopes/checkout/stores/cartStore'
import InsuranceCompliancy from '~/scopes/insurance/components/InsuranceCompliancy/InsuranceCompliancy.vue'
import { hasOfferCompliancyError } from '~/scopes/insurance/utils/insuranceOffers'

import translations from './Insurances.translations'
import CatchUpModal from './components/CatchUpModal/CatchUpModal.vue'
import InsuranceSelection from './components/InsuranceSelection/InsuranceSelection.vue'
import InsuranceSelectionRedesign from './components/InsuranceSelectionRedesign/InsuranceSelectionRedesign.vue'
import SelectedCatchUpOffer from './components/SelectedCatchUpOffer/SelectedCatchUpOffer.vue'

const props = defineProps<{
  shouldDisplayError: boolean
  withCatchUpModal: boolean
  listingId: string
  model: string
  insuranceOffers: InsuranceOfferWithSelectedState[]
}>()

const emit = defineEmits<{
  update: [updatedInsuranceOffers: InsuranceOfferWithSelectedState[]]
  'ignore-catchup-modal': []
}>()

const experiments = useExperiments()
const i18n = useI18n()
const { handleUnauthorizedUser } = useHandleUnauthorizedUser()
const { openSuccessToast, openErrorToast } = useTheToast()

const selectedOffer = ref<InsuranceOfferWithSelectedState>(
  props.insuranceOffers.find((offer) => offer.selected) ||
    props.insuranceOffers.find((offer) => offer.defaultOffer) ||
    props.insuranceOffers[0],
)

const cartStore = useCartStore()

const hasCompliancyError = computed(() =>
  props.insuranceOffers.some(hasOfferCompliancyError),
)

const isCatchUpOfferSelected = computed(() => {
  return props.insuranceOffers.some((offer) => {
    return offer.isCatchUpOffer && offer.selected
  })
})

const shouldDisplayInsuranceOffers = computed(() => {
  const hasInsuranceOffers =
    props.insuranceOffers.filter(
      (offer) => !offer.isCatchUpOffer && !offer.defaultOffer,
    ).length > 0

  if (!hasInsuranceOffers) return false

  // If catchup offer is selected, we want to display SelectedCacthupOffer instead
  if (isCatchUpOfferSelected.value) return false

  return true
})

const isInsuranceSelectionRedesignEnabled = computed(
  () => experiments['experiment.insuranceSelectionRedesign'] === 'newDesign',
)

const handleUpdateCompliancy = async (updatedInsurance: InsuranceOffer) => {
  selectedOffer.value = {
    ...updatedInsurance,
    selected: selectedOffer.value.selected,
  }

  try {
    await $httpFetch(postAcceptAgreement, {
      body: {
        listingId: props.listingId,
        insuranceOfferId: updatedInsurance.id,
      },
      queryParams: {
        monthlyInsuranceSupported: true,
      },
    })

    const updatedInsuranceOffers = props.insuranceOffers.reduce<
      InsuranceOfferWithSelectedState[]
    >((offers, offer) => {
      if (offer.id === selectedOffer.value.id) {
        return [...offers, selectedOffer.value]
      }

      return [...offers, offer]
    }, [])

    emit('update', updatedInsuranceOffers)
  } catch (error) {
    await handleUnauthorizedUser(
      error as Record<string, unknown>,
      '[CHECKOUT] Unhandled error updating terms and conditions',
    )

    throw error
  }
}

const updateSelectedInsuranceInCart = useThrottleFn(
  async function updateSelectedInsuranceInCart() {
    try {
      await $httpFetch(postUpdateInsuranceOffer, {
        body: {
          listingId: props.listingId,
          insuranceOfferId: selectedOffer.value.id,
        },
        queryParams: {
          monthlyInsuranceSupported: true,
        },
      })

      const updatedInsuranceOffers = props.insuranceOffers.reduce<
        InsuranceOfferWithSelectedState[]
      >((offers, offer) => {
        if (offer.id === selectedOffer.value.id) {
          return [...offers, selectedOffer.value]
        }

        return [...offers, { ...offer, selected: false }]
      }, [])

      emit('update', updatedInsuranceOffers)
    } catch (error) {
      await handleUnauthorizedUser(
        error as Record<string, unknown>,
        '[CHECKOUT] Unhandled error updating insurance offer',
      )

      throw error
    }
  },
  1000,
  true,
)

const updateSelectedOption = async (updatedInsurance: InsuranceOffer) => {
  selectedOffer.value = { ...updatedInsurance, selected: true }
  await updateSelectedInsuranceInCart()
}

async function handleSelectCatchUpModal(insuranceOffer: InsuranceOffer) {
  closeModal()
  cartStore.skipCatchupModal()

  try {
    await updateSelectedOption(insuranceOffer)

    openSuccessToast({
      content: i18n(translations.successfullyAddedToast),
    })
  } catch (error) {
    openErrorToast()

    throw error
  }
}

const handleDeclineCatchUpModal = () => {
  closeModal()
  cartStore.skipCatchupModal()
  emit('ignore-catchup-modal')
}

const handleRemoveOffer = async () => {
  const defaultOffer = props.insuranceOffers.find((offer) => {
    return offer.defaultOffer
  })

  if (defaultOffer) {
    updateSelectedOption(defaultOffer)
  }
}
</script>
