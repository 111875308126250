<template>
  <RevInputText
    v-if="isInputVisible"
    :id
    ref="input"
    :error
    :label="i18n(translations.label, { listing: listingTitle })"
    min="1"
    :model-value
    pattern="[0-9]*"
    size="small"
    type="number"
    @blur="handleInputBlur"
    @focus="handleInputFocus"
    @input="handleInputInput"
  />

  <RevInputSelect
    v-if="!isInputVisible"
    :id
    data-test="quantity-selector"
    :disabled
    :error
    :label="i18n(translations.label, { listing: listingTitle })"
    :model-value
    :options
    size="small"
    @update:model-value="
      (value) =>
        emit('update:modelValue', typeof value === 'string' ? value : '1')
    "
  />
</template>

<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputSelect } from '@ds/components/InputSelect'
import { RevInputText } from '@ds/components/InputText'

import translations from './QuantitySelector.translations'

const DEFAULT_LIMIT = 10

const i18n = useI18n()

defineOptions({
  inheritAttrs: false,
})

const emit = defineEmits<{
  'update:modelValue': [newValue: string]
}>()

const props = withDefaults(
  defineProps<{
    error?: string
    id: string
    listingTitle: string
    limit?: number
    max?: number
    modelValue: string
    disabled?: boolean
  }>(),
  {
    error: '',
    max: Number.MAX_SAFE_INTEGER,
    limit: DEFAULT_LIMIT,
  },
)

const input = ref<null | { $el: HTMLDivElement }>(null)
const inputHasFocus = ref(false)

const parsedValue = computed(() => parseInt(props.modelValue, 10))

const hasMoreThanLimit = computed(() => props.max > props.limit)

const isValueInvalid = computed(
  () => Number.isNaN(parsedValue.value) || parsedValue.value < 1,
)

const isValueOutOfSelectBounds = computed(
  () =>
    isValueInvalid.value ||
    (hasMoreThanLimit.value && parsedValue.value >= props.limit),
)

const isInputVisible = computed(
  () => isValueOutOfSelectBounds.value || inputHasFocus.value,
)

const options = computed(() => {
  const length = Math.min(props.max, props.limit)

  const isLastWithMore = (i: number) =>
    i === length - 1 && hasMoreThanLimit.value

  return Array.from({ length }, (_, i) => {
    const value = String(i + 1)
    const label = `${value}${isLastWithMore(i) ? '+' : ''}`

    return { value, label }
  })
})

const focusInput = () => {
  if (input.value) {
    input.value.$el.querySelector('input')?.focus()
  }
}

watch(isInputVisible, async (isVisible) => {
  /**
   * Focus on input immediately, when "select => input" swap happens
   */
  if (isVisible) {
    await nextTick()
    focusInput()
  }
})

function handleInputFocus() {
  inputHasFocus.value = true
}

function handleInputBlur(e: Event) {
  const { target } = e
  const { value } = target as HTMLInputElement
  inputHasFocus.value = false

  // Reset to 1 when value is empty
  if (value === '' || value === '0') {
    emit('update:modelValue', '1')
  }
}

function handleInputInput(e: Event) {
  const { target } = e
  const { value } = target as HTMLInputElement

  emit('update:modelValue', value === '' || value === '0' ? '1' : value)
}
</script>
