<template>
  <div class="space-y-8">
    <FormattedMessage
      class="text-static-default-hi body-1"
      :definition="translations.title"
    >
      <template #deviceName>
        <span class="body-1-bold"> {{ model }}</span>
      </template>
    </FormattedMessage>

    <div class="space-y-12">
      <InsuranceOffers
        :id="listingId"
        data-test="insurances"
        :offers="insuranceOffers"
        :selected-offer-id
        :tracking-zone
        @select="(offer) => emit('update', offer)"
      />

      <InsuranceProvider :tracking-zone with-link />
    </div>
  </div>
</template>

<script setup lang="ts">
import type {
  InsuranceOffer,
  InsuranceOfferWithSelectedState,
} from '@backmarket/http-api'
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'

import InsuranceOffers from '~/scopes/insurance/components/InsuranceOffers/InsuranceOffers.vue'
import InsuranceProvider from '~/scopes/insurance/components/InsuranceOffers/components/InsuranceProvider/InsuranceProvider.vue'

import translations from './InsuranceSelection.translations'

defineProps<{
  listingId: string
  model: string
  selectedOfferId: string | number
  insuranceOffers: InsuranceOfferWithSelectedState[]
}>()

const emit = defineEmits<{
  update: [updatedInsuranceOffers: InsuranceOffer]
}>()

const trackingZone = 'cart'
</script>
