export default {
  noItemsSurtitle: {
    id: 'cart_no_items_surtitle',
    defaultMessage: 'Buzzkill alert',
  },
  noItemsTitle: {
    id: 'cart_no_items_title',
    defaultMessage: "There's nothing in your cart.",
  },
  noItemsDescription: {
    id: 'cart_no_items_description',
    defaultMessage: "All this stellar refurb isn't gonna shop itself!'",
  },
  noItemsButton: {
    id: 'cart_no_items_button',
    defaultMessage: 'Browse sweet deals',
  },
}
