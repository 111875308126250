export default {
  compliancyError: {
    id: 'trutpack_cgv_error',
    defaultMessage: 'Thanks for accepting the general buying conditions.',
  },
  successfullyAddedToast: {
    id: 'catch_up_modal_offer_insurance_toast_added_to_cart',
    defaultMessage: 'Added to cart',
  },
}
