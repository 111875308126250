export default {
  youSaveDisplay: {
    id: 'product_page_you_save_amount_display',
    defaultMessage: 'Save {reduction}',
  },
  bundlingDiscount: {
    id: 'funnel_cart_bundle_discount',
    defaultMessage: 'Bundling saved you {price}',
  },
}
