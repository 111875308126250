<template>
  <RevLink
    :class="linkClassnames"
    @click="openModal(MODAL_NAMES.CART_WARRANTY)"
  >
    {{ i18n(translations.warranty) }}
  </RevLink>
  <ClientOnly>
    <WarrantyModal :name="MODAL_NAMES.CART_WARRANTY" />
  </ClientOnly>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevLink } from '@ds/components/Link'
import { openModal } from '@ds/components/ModalBase'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'
import WarrantyModal from '~/scopes/reassurance/components/WarrantyModal/WarrantyModal.vue'

import translations from './Warranty.translations'

const i18n = useI18n()

withDefaults(
  defineProps<{
    linkClassnames?: string
  }>(),
  {
    linkClassnames: '',
  },
)
</script>
