<template>
  <Bundle>
    <div class="flex justify-between">
      <div class="flex flex-col gap-4">
        <div>
          <RevIllustration
            :alt="i18n(translations.illustrationAlternative)"
            :height="21"
            src="/img/insurances/backUpLogo.svg"
            :width="96"
          />
        </div>
        <div class="text-static-default-hi body-1-bold">
          {{ insuranceOffer.title }}
        </div>
      </div>
      <RevButton
        class="hidden md:block"
        size="small"
        variant="secondaryDestructive"
        @click="removeOffer"
      >
        {{ i18n(translations.remove) }}
      </RevButton>
    </div>

    <div class="text-static-default-hi heading-2 my-4">
      {{ i18n.price(insuranceOffer.price) }}
    </div>

    <div class="flex items-end justify-between">
      <RevLink
        target="_blank"
        :to="i18n(translations.faqLinkValue)"
        :tracking="{
          name: 'insurance_faq',
          zone: 'cart_insurance_selected',
          offer_id: props.insuranceOffer.id,
        }"
      >
        <div class="text-static-default-low body-2-link">
          {{ i18n(translations.faqLink) }}
        </div>
      </RevLink>

      <RevButton
        class="md:hidden"
        size="small"
        variant="secondaryDestructive"
        @click="emit('remove')"
      >
        {{ i18n(translations.remove) }}
      </RevButton>
    </div>

    <div class="mt-12 md:mt-8">
      <InsuranceCompliancy
        id="selected-offer-catchup"
        :offer="insuranceOffer"
        @update="handleCompliancyCheckboxUpdate"
      />
    </div>
  </Bundle>
</template>

<script setup lang="ts">
import type { InsuranceOffer } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import InsuranceCompliancy from '~/scopes/insurance/components/InsuranceCompliancy/InsuranceCompliancy.vue'

import Bundle from '../../../Bundle/Bundle.vue'

import translations from './SelectedCatchUpOffer.translations'

const props = defineProps<{
  insuranceOffer: InsuranceOffer
}>()

const emit = defineEmits<{
  'update-compliancy': [updatedOffer: InsuranceOffer]
  remove: []
}>()

const i18n = useI18n()

function handleCompliancyCheckboxUpdate(insuranceOffer: InsuranceOffer) {
  emit('update-compliancy', insuranceOffer)
}

const tracking = useTracking()

function removeOffer() {
  tracking.trackClick({
    name: 'remove_insurance_from_cart',
    zone: 'cart_insurance_selected',
    offer_id: props.insuranceOffer.id,
  })

  emit('remove')
}
</script>
