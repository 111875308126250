<template>
  <div>
    <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />

    <RecommendationCarousel
      :options="{
        withCrossedPrice: true,
        withStartingFrom: false,
        withGrade: true,
        withAddToCart: true,
      }"
      :recommendation-query="{
        category: 'crossSellWeb',
        limit: 12,
        personalisation: true,
        scope: 'cart',
        scopeId: recommendationIds,
      }"
      :title="i18n(translations.titleCrossSell)"
      :trackingData="{
        list: 'cross sell',
      }"
      @loaded="handleCrossSellLoaded"
      @refresh="handleCrossSellRefresh"
    />

    <RevDivider v-if="isCrossSellVisible" class="my-40 md:my-56" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import type { Product } from '@backmarket/nuxt-layer-recommendation/models/product'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevDivider } from '@ds/components/Divider'

import { useCartStore } from '../../../../stores/cartStore'

import translations from './CrossSell.translations'

const i18n = useI18n()
const cartStore = useCartStore()
const isCrossSellVisible = ref(false)

const recommendationIds = computed(() =>
  cartStore.items.reduce((acc, item) => `${acc},${item.listingId}`, ''),
)

const handleCrossSellLoaded = (results: Product[]) => {
  if (isEmpty(results)) {
    isCrossSellVisible.value = false
  } else {
    isCrossSellVisible.value = true
  }
}

const handleCrossSellRefresh = async () => {
  await cartStore.fetchCart()
}
</script>
