export default {
  title: {
    id: 'warranty_extension_modal_title_offer',
    defaultMessage: 'Get protected for the unexpected',
  },
  accept: {
    id: 'warranty_extension_modal_accept',
    defaultMessage: 'Add warranty extension',
  },
  ignore: {
    id: 'warranty_extension_modal_ignore',
    defaultMessage: 'Continue without extension',
  },
  close: {
    id: 'warranty_extension_modal_close_modal',
    defaultMessage: 'Close',
  },
}
