<template>
  <RevTooltip class="w-60" content-class-names="m-5" position="auto">
    <template #trigger="{ show, hide }">
      <QuantitySelector
        :id
        :disabled
        :error
        :listing-title
        :max
        :min="1"
        :model-value="modelValue.toString()"
        @update:model-value="
          (newValue: string) => onQuantityChange({ newValue, hide, show })
        "
      />
    </template>

    {{ error }}
  </RevTooltip>
</template>

<script setup lang="ts">
import { ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTooltip } from '@ds/components/Tooltip'

import translations from './ArticleQuantitySelector.translations'
import QuantitySelector from './QuantitySelector.vue'

const i18n = useI18n()

const emit = defineEmits<{
  'update:modelValue': [newValue: number]
}>()

const props = defineProps<{
  id: string
  listingTitle: string
  max: number
  modelValue: number
  disabled?: boolean
}>()

const previousValidValue = ref(0)
const error = ref('')

function onQuantityChange({
  show,
  hide,
  newValue,
}: {
  show: () => void
  hide: () => void
  newValue: string
}) {
  const parsedValue = parseInt(newValue, 10)

  if (parsedValue > props.max) {
    error.value = i18n(translations.maxUnitsAvailable, { max: props.max })
    show()
  } else {
    error.value = ''
    hide()
  }

  if (parsedValue <= props.max && parsedValue !== previousValidValue.value) {
    previousValidValue.value = parsedValue
  }
  emit('update:modelValue', parsedValue)
}
</script>
