export default {
  productOutOfStock: {
    id: 'product_no_available',
    defaultMessage: 'Oh fiddlesticks! This product is not available',
  },
  productGrade: {
    id: 'product_grade',
    defaultMessage: 'Esthetic condition:',
  },
  productRemove: {
    id: 'product_remove',
    defaultMessage: 'Remove',
  },
  productGradeWithNewBattery: {
    id: 'product_grade_with_new_battery',
    defaultMessage: '{grade} with new battery',
  },
  itemInStock: {
    id: 'cart_in_stock',
    defaultMessage: 'In Stock',
  },
  itemLowStock: {
    id: 'cart_low_stock',
    defaultMessage: 'Only {stock} left',
  },
}
