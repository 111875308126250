export default {
  shippingPromise: {
    id: 'cart_shipping_promise',
    defaultMessage: 'Get it by {earliest} - {latest} • Free',
  },
  shippingPromiseExpress: {
    id: 'cart_express_shipping_promise',
    defaultMessage: 'Express by {latest} • from {amount}',
  },
}
