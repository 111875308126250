<template>
  <div
    v-if="popularityStats && isWithSalesPopularityTag"
    class="rounded-t-lg w-full px-16 pb-18 pt-6"
    :class="backgroundColor"
  >
    <span
      class="body-2-bold flex flex-row items-center self-center"
      :class="textColor"
    >
      <DynamicIcon v-if="icon" :class="textColor" :icon size="medium" />
      <span class="ml-2">{{ popularityStats.tags[0].text }}</span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { PopularityStats } from '@backmarket/http-api/src/api-specs-checkout/popularity-stats/popularity-stats.types'
import type { IconName } from '@backmarket/http-api/src/api-specs-content/models/icon'
import DynamicIcon from '@backmarket/nuxt-layer-cms/DynamicIcon.vue'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'

const props = defineProps<{
  popularityStats?: PopularityStats
  isOutOfStock: boolean
}>()

const experiments = useExperiments()

const backgroundColor = computed(() => {
  return (
    props.popularityStats?.tags?.[0]?.tokens?.backgroundColor?.replace(
      'color-background',
      'bg',
    ) ?? 'bg-surface-brand-hi'
  )
})

const textColor = computed(() => {
  return (
    props.popularityStats?.tags?.[0].tokens?.textColor?.replace(
      'color-text',
      'text',
    ) ?? 'text-static-brand-mid'
  )
})

const icon = computed(() => {
  return (props.popularityStats?.tags?.[0]?.tokens?.icon as IconName) ?? null
})

const isWithSalesPopularityTag = computed(
  () =>
    experiments['experiment.newSalesPopularityTag'] ===
      'popularityTagDisplayed' &&
    !props.isOutOfStock &&
    props.popularityStats?.tags.length,
)
</script>
