<template>
  <RevTooltip position="bottom">
    <template #trigger="{ hide, show }">
      <button
        class="text-static-default-low body-2-striked cursor-pointer whitespace-nowrap"
        @click="show"
        @focusin="show"
        @focusout="hide"
        @mouseenter="show"
        @mouseleave="hide"
      >
        {{
          i18n(translations.originalPriceDisplay, {
            price: i18n.price(price),
          })
        }}
      </button>
    </template>

    {{ i18n(translations.strikedPriceExplanation) }}
  </RevTooltip>
</template>

<script lang="ts" setup>
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTooltip } from '@ds/components/Tooltip'

import translations from './StrikedProductPrice.translations'

defineProps<{
  price: number
}>()

const i18n = useI18n()
</script>
