<template>
  <RevDialog
    :closeButtonLabel="i18n(translations.close)"
    :name="MODAL_NAMES.CATCH_UP_INSURANCE_OFFER"
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="space-y-16">
        <div class="relative">
          <div class="flex justify-center">
            <RevIllustration
              alt=""
              class="z-10"
              :height="196"
              src="/img/checkout/insuranceModal.svg"
              :width="220"
            />
          </div>
          <div
            class="rounded-lg absolute top-[22px] flex h-[150px] w-full bg-[#6E83F0]"
          />
        </div>

        <CatchUpModalOffer :device-name :insurance-offer />
      </div>
    </template>

    <template #footer>
      <div class="space-y-12">
        <RevButton
          full-width="always"
          variant="primary"
          @click="emit('select-offer', insuranceOffer)"
        >
          {{ i18n(translations.accept) }}
        </RevButton>
        <RevButton
          data-qa="continue-without-insurance"
          full-width="always"
          variant="secondary"
          @click="emit('decline', insuranceOffer)"
        >
          {{ i18n(translations.ignore) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import type { InsuranceOffer } from '@backmarket/http-api'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'

import { MODAL_NAMES } from '~/scopes/checkout/config/constants'

import CatchUpModalOffer from '../CatchUpModalOffer/CatchUpModalOffer.vue'

import translations from './CheapestInsuranceOfferModal.translations'

defineProps<{
  insuranceOffer: InsuranceOffer
  deviceName: string
}>()

const emit = defineEmits<{
  'select-offer': [insuranceOffer: InsuranceOffer]
  decline: [insuranceOffer: InsuranceOffer]
}>()

const i18n = useI18n()
</script>
