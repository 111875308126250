export default {
  minimumCoverageDuration: {
    id: 'insurance_radio_minimum_coverage_duration',
    defaultMessage: '1-year minimum ({annualPrice})',
  },
  monthlyPrice: {
    id: 'insurance_radio_monthly_price',
    defaultMessage: '{price} /month',
  },
  oneTimePayment: {
    id: 'insurance_radio_one_time_payment',
    defaultMessage: 'One-time payment',
  },
}
