export default {
  recommendedTag: {
    id: 'insurance_offers_recommended_tag',
    defaultMessage: 'Recommended',
  },
  monthlyPaymentTag: {
    id: 'insurance_offers_monthly_payment_tag',
    defaultMessage: 'Monthly payment',
  },
}
