<template>
  <WarrantyExtensionModal
    v-if="warrantyExtensionInsuranceOffer"
    :device-name
    :insurance-offer="warrantyExtensionInsuranceOffer"
    @decline="handleDecline"
    @select-offer="handleSelectOffer"
  />

  <template v-else>
    <CheapestInsuranceOfferModal
      v-if="cheapestInsuranceOffer"
      :device-name
      :insurance-offer="cheapestInsuranceOffer"
      @decline="handleDecline"
      @select-offer="handleSelectOffer"
    />
  </template>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { InsuranceOffer } from '@backmarket/http-api'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import CheapestInsuranceOfferModal from './components/CheapestInsuranceOfferModal/CheapestInsuranceOfferModal.vue'
import WarrantyExtensionModal from './components/WarrantyExtensionModal/WarrantyExtensionModal.vue'

const props = defineProps<{
  deviceName: string
  insuranceOffers: InsuranceOffer[]
}>()

const emit = defineEmits<{
  (e: 'select-insurance-offer', insuranceOffer: InsuranceOffer): void
  (e: 'decline'): void
}>()

const cheapestInsuranceOffer = computed(() => {
  return props.insuranceOffers
    .filter(
      (insuranceOffer) =>
        !insuranceOffer.defaultOffer && !insuranceOffer.isCatchUpOffer,
    )
    .reduce<InsuranceOffer | null>((cheapestOffer, insuranceOffer) => {
      if (!cheapestOffer) return insuranceOffer

      if (
        parseFloat(insuranceOffer.price.amount) <
        parseFloat(cheapestOffer.price.amount)
      )
        return insuranceOffer

      return cheapestOffer
    }, null)
})

const warrantyExtensionInsuranceOffer = computed(() => {
  return props.insuranceOffers.find((offer) => offer.isCatchUpOffer)
})

const tracking = useTracking()

function handleSelectOffer(insuranceOffer: InsuranceOffer) {
  tracking.trackClick({
    name: 'select_offer',
    zone: 'cart_catchup_modal',
    offer_id: insuranceOffer.id,
  })

  emit('select-insurance-offer', insuranceOffer)
}

function handleDecline(insuranceOffer: InsuranceOffer) {
  tracking.trackClick({
    name: 'decline',
    zone: 'cart_catchup_modal',
    offer_id: insuranceOffer.id,
  })

  emit('decline')
}
</script>
